import React, { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import Popup from "reactjs-popup";
import Notify from "../../helpers/Notifier";
import { ApplicationFormContext } from "../ApplicationFormProvider";
import MiniLoad from "../MiniLoad/MiniLoad";

type Props = {
  open: boolean;
  handleClose: () => void;
  procTaskId: any;
};

const NcaLayerSignModal: React.FC<Props> = ({
  open,
  handleClose,
  procTaskId,
}) => {
  const { signApplication, loading } = useContext(ApplicationFormContext);

  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

  const getXmlData = () => {
    const formHtml = document.getElementById("formToSign");
    return new XMLSerializer().serializeToString(formHtml);
  };

  const onWebSocketConnectionOpened = (socket: WebSocket) => {
    if (webSocket) webSocket.close();
    const data = getXmlData();
    socket.send(
      JSON.stringify({
        module: "kz.gov.pki.knca.basics",
        method: "sign",
        args: {
          allowedStorages: [
            "PKCS12",
            "AKKaztokenStore",
            "AKKZIDCardStore",
            "AKEToken5110Store",
          ],
          format: "xml",
          data,
          signingParams: {},
          signerParams: {
            extKeyUsageOids: ["1.3.6.1.5.5.7.3.4"],
          },
          locale: "ru",
        },
      })
    );
    setWebSocket(socket);
  };

  const onMessageReceived = (event: MessageEvent<string>) => {
    const NCALayerResponse = JSON.parse(event.data) as any;
    if (NCALayerResponse.status && NCALayerResponse.body?.result?.length) {
      const data = NCALayerResponse.body.result[0];
      signApplication(procTaskId, data);
    }
  };

  const connectToSocket = () => {
    const webSocket = new WebSocket("wss://127.0.0.1:13579/");
    webSocket.onopen = () => onWebSocketConnectionOpened(webSocket);
    webSocket.onmessage = (ev) => onMessageReceived(ev);
    webSocket.onclose = (ev) => closeConnection(ev);
  };

  const closeConnection = (event: CloseEvent) => {
    console.log("connection has been closed");
    if (!event.wasClean) {
      Notify.pushErrorMessage(
        "Ошибка при подключении к NCALayer.",
        "Убедитесь что программа NCALayer запущена и повторите попытку."
      );
    }
  };

  useEffect(() => {
    if (!open && webSocket) {
      webSocket.close();
    }
  }, [open]);

  return (
    <Popup open={open} modal closeOnDocumentClick onClose={handleClose}>
      {(close) => (
        <div className="avatar-modal">
          <div>
            <div className="avatar__container">
              <div className="avatar__content">
                <div className="avatar__wrap-input">
                  <label
                    className="avatar__label avatar__label--cabinet"
                    htmlFor="file_id"
                  >
                    <Trans>AttachESP</Trans>
                  </label>
                </div>
                <div className="btn__actions btn__actions--avatar">
                  <button
                    disabled={loading}
                    className="button-input btn btn-success flex justify-content-center align-items-center"
                    style={{ marginTop: "0", minWidth: "126px" }}
                    onClick={connectToSocket}
                  >
                    {loading ? <MiniLoad /> : <Trans>Send</Trans>}
                  </button>
                  <button
                    className="button-input btn btn-light btn-popup"
                    onClick={close}
                  >
                    <Trans>Cancel</Trans>
                  </button>
                </div>
              </div>
              <span
                onClick={handleClose}
                className="icon-delete top-right"
                style={{ marginTop: "5px" }}
              >
                +
              </span>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default NcaLayerSignModal;
