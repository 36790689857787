import React, { Fragment, useContext, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import i18next from "i18next";
import { cubaREST } from "../cubaREST";
import { ApplicationFormContext } from "components/ApplicationFormProvider";
import eyeIcon from "../assets/icons/eye.svg";
import CustomProgressBar from "../components/ProgressBar/ProgressBar";
import ResetFormButton from "../components/ResetFormButton/ResetFormButton";
import Notify from "./Notifier";
import NcaLayerSignModal from "../components/NCALayerSignModal";

const LoadButtons = (props) => {
  const btnRef = useRef(null);
  const { appId, buttonsInfo, loading, loadingECP, isECPsigning } = props;
  const {
    saveApplication,
    saveAndCheckApplicationBeforeSign,
    applicationForm,
    applicant,
    verifyApplication,
  } = useContext(ApplicationFormContext);

  const [openSign, setOpenSign] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);

  const showPopup = async (btn) => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      setDisabled(true);
      Notify.pushSuccessMessage(i18next.t("applicationSignIn"));
      return;
    } else {
      setDisabled(false);
      const isValidForm = verifyApplication(applicationForm, applicant);
      if (isValidForm) {
        const isFormVerified = await saveAndCheckApplicationBeforeSign(btn);
        isFormVerified && setOpenSign(true);
      }
    }
  };

  return (
    <>
      {buttonsInfo &&
      Object.keys(buttonsInfo).length > 0 &&
      applicationForm.programs?.length ? (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              gap: "10px",
              position: "sticky",
              bottom: "0",
              background: "#fff",
              paddingBottom: "20px",
            }}
          >
            <ResetFormButton loading={loading} isECPsigning={isECPsigning} />
            {Object.keys(buttonsInfo).map((buttonKey: string) => {
              const button = buttonsInfo[buttonKey];

              if (buttonKey === "saveTemp") {
                return (
                  <button
                    key={buttonKey}
                    className={"button-input btn btn-save"}
                    onClick={() => {
                      saveApplication(button.procTaskId, "temp");
                    }}
                    disabled={loading || isECPsigning}
                  >
                    <Trans>saveTemp</Trans>
                  </button>
                );
              }

              if (buttonKey === "sign") {
                return (
                  <Fragment key={buttonKey}>
                    <button
                      ref={btnRef}
                      className="button-input btn btn-success btnSignIn btn-mob"
                      disabled={loadingECP || disabled || isECPsigning}
                      onClick={() => {
                        showPopup(button.procTaskId);
                      }}
                    >
                      <Trans>{buttonKey}</Trans>
                    </button>

                    <NcaLayerSignModal
                      open={openSign}
                      handleClose={() => setOpenSign(false)}
                      procTaskId={button.procTaskId}
                    />
                  </Fragment>
                );
              }
            })}
            <span className="progressName">
              <Trans>CompletionProgress</Trans>:
            </span>
            <div className="conteinerProgress">
              <CustomProgressBar />
            </div>
          </div>

          {!Object.keys(buttonsInfo).length && (
            <div className="process_application-form__footer">
              <h2
                style={{
                  display: "flex",

                  justifyContent: "center",
                }}
                className="red-error"
              >
                <Trans>YouHaveSent</Trans>
              </h2>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default LoadButtons;
